import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import PropTypes from 'prop-types'
import de from 'date-fns/locale/de'
import en from 'date-fns/locale/en-GB'

import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { StyledInputStyled, StyledPasswordIcon } from './StyledInput.styles'
import CustomCheckboxRadio from '../CustomCheckboxRadio/CustomCheckboxRadio'

import Select from 'react-select'
import DatePickerUi from './DatePickerUi/DatePickerUi'

registerLocale('de', de)
registerLocale('en', en)

const StyledInput = (props) => {
  const locales = {
    en: en,
    de: de
  }

  const {
    reactSelectOptions = [{ value: 'default', label: 'default' }],
    autoComplete = 'off',
    isLoginInput = false
  } = props

  // Focus by default when it is a login input
  const [focus, setFocus] = useState(isLoginInput)
  const [inputType, setInputType] = useState(props.type)
  const calendarRef = useRef(false)

  const generateInput = useCallback(() => {
    const setFieldValue = props.setFieldValue
    const onBlur = props.onBlur
    const onFocus = props.onFocus

    const addProps = {
      style: props.style,
      id: props.id,
      disabled: props.disabled,
      name: props.name,
      onChange: props.onChange,
      onBlur: () => {
        setFocus(false)
        onBlur && onBlur()
      },
      onFocus: () => {
        setFocus(true)
        onFocus && onFocus()
      },
      type: inputType,
      value: props.value,
      autoComplete,
      maxLength: props.maxLength
    }

    switch (props.type) {
      case 'textarea':
        return <textarea {...addProps}>{props.value}</textarea>
      case 'checkbox':
        return (
          <CustomCheckboxRadio
            style={props.style}
            theme={props.theme}
            type='checkbox'
            name={props.name}
            id={props.id}
            onChange={props.onChange}
            isChecked={props.value}
            disabled={props.disabled}
            labelElement={props.label}
            overrideActiveIcon={props.overrideActiveIcon}
            overrideDeactivatedIcon={props.overrideDeactivatedIcon}
          />
        )

      case 'select':
        return (
          <select {...addProps}>
            <option value='' disabled />
            {props.optionsHtmlElemets}
          </select>
        )

      case 'reactSelect':
        return (
          <Select
            classNamePrefix='reactSelectFilter'
            options={reactSelectOptions.map((item) => {
              return { value: item?.value?.toString(), label: item?.label }
            })}
            style={props.style}
            theme={props.theme}
            name={props.name}
            placeholder=''
            isClearable={true}
            id={props.id}
            defaultValue={reactSelectOptions.find((item) => {
              return item?.value?.toString() === props?.value?.toString()
            })}
            onChange={(selectedOption) => {
              props.onChange && props.onChange(selectedOption?.value || '')
              props.setFieldValue(props.name, selectedOption?.value || '')
            }}
            onBlur={() => {
              setFocus(false)
              onBlur && onBlur()
            }}
            onFocus={() => {
              setFocus(true)
              onFocus && onFocus()
            }}
            autoComplete='off'
          />
        )

      case 'date':
        // eslint-disable-next-line no-case-declarations
        const CustomFloatingLabelWithRef = forwardRef(
          ({ value, onClick }, _ref) => (
            <input
              style={props.style}
              id={props.id}
              className={props.className}
              type='text'
              readOnly
              name={props.name}
              onChange={props.onChange}
              value={value}
              autoComplete='off'
              onBlur={props.onBlur}
              onClick={onClick}
            />
          )
        )

        CustomFloatingLabelWithRef.displayName = 'CustomFloatingLabelWithRef' // Just to prevent forwardRef missing displayName warning

        return (
          <DatePicker
            ref={calendarRef}
            style={props.style}
            disabled={props.disabled}
            className=''
            locale={props.locale}
            selected={props.value}
            onKeyDown={(e) => e.preventDefault()}
            dateFormat='P'
            customInput={<CustomFloatingLabelWithRef />}
            onChange={(newDate) => {
              setFieldValue(props.name, newDate)
              setTimeout(() => {
                calendarRef.current.setOpen(false)
              }, 100)
            }}
          />
        )

      case 'dateUi':
        return (
          <DatePickerUi
            disableMaskedInput
            variant='inline'
            setFieldValue={setFieldValue}
            locale={props.locale}
            locales={locales}
            setFocus={setFocus}
            onBlur={onBlur}
            onFocus={onFocus}
            value={props.value}
            name={props.name}
          />
        )

      default:
        return <input {...addProps} />
    }
  }, [
    props.id,
    props.locale,
    props.name,
    props.onChange,
    props.className,
    props.onBlur,
    props.onFocus,
    props.type,
    props.value,
    props.optionsHtmlElemets,
    props.label,
    props.setFieldValue,
    props.disabled,
    props.theme,
    inputType,
    focus
  ])

  useEffect(() => {
    setDefaultLocale(props.locale)
  }, [props.locale])

  const generateFeedback = useCallback(() => {
    const isInvalid = props.feedbackMessage
    return (
      <div
        className={`feedback ${props.feedbackStyle && props.feedbackStyle} `}
      >
        {isInvalid}
      </div>
    )
  }, [props.feedbackMessage, props.feedbackStyle])

  const generateFloatingPlaceholder = useCallback(() => {
    const spanClassName = `floating-text ${
      (props.value || focus) && 'floating'
    }`

    switch (props.type) {
      case 'checkbox':
        return null

      default:
        return <span className={spanClassName}>{props.label}</span>
    }
  }, [props.label, props.value, focus, props.type])

  return (
    <span className='input-wrapper'>
      <StyledInputStyled
        focus={focus}
        disabled={props.disabled}
        theme={props.theme}
        className={props.className}
        htmlFor={props.id}
      >
        {generateFloatingPlaceholder()}
        {generateInput()}
        {generateFeedback()}
      </StyledInputStyled>

      {props.type === 'password' && (
        <StyledPasswordIcon
          icon={inputType === 'password' ? 'password-show' : 'password-hide'}
          size='1em'
          aria-hidden='true'
          onClick={(event) => {
            event.stopPropagation()
            if (inputType === 'password') {
              setInputType('text')
            } else {
              setInputType('password')
            }
          }}
        />
      )}
    </span>
  )
}

StyledInput.defaultProps = {
  type: 'text',
  locale: 'en',
  feedbackStyle: 'invalid',
  disabled: false
}

StyledInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  /**
   * This is required because this component works only with formik!
   */
  setFieldValue: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
  label: PropTypes.any,
  className: PropTypes.string,
  value: PropTypes.any,
  feedbackMessage: PropTypes.string,
  feedbackStyle: PropTypes.string,
  disabled: PropTypes.bool,
  /**
   * Only 'de' or 'en' available
   */
  locale: PropTypes.string,
  /**
   * Define the options for an select field
   */
  optionsHtmlElemets: PropTypes.arrayOf(PropTypes.element),
  theme: PropTypes.shape({
    components: PropTypes.shape({
      styledInput: PropTypes.shape({
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: PropTypes.array,
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        fontFamily: PropTypes.string,
        borderColor: PropTypes.string,
        focus: PropTypes.shape({
          borderColor: PropTypes.string
        }),
        span: PropTypes.shape({
          color: PropTypes.string,
          fontSize: PropTypes.string,
          fontWeight: PropTypes.string,
          fontFamily: PropTypes.string
        }),
        invalid: PropTypes.shape({
          color: PropTypes.string,
          borderColor: PropTypes.string
        }),
        feedback: PropTypes.shape({
          fontSize: PropTypes.string,
          fontWeight: PropTypes.string,
          fontFamily: PropTypes.string
        })
      })
    })
  })
}

export default StyledInput
