import React, { useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format } from 'date-fns'

const DatePickerUi = (props) => {
  const {
    setFieldValue,
    locales,
    setFocus,
    onBlur,
    onFocus,
    value,
    name,
    locale
  } = props
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locales[locale]}
    >
      <DatePicker
        open={datePickerOpen}
        onOpen={() => {
          setDatePickerOpen(true)
        }}
        onClose={() => {
          setDatePickerOpen(false)
        }}
        defaultValue={format(new Date(), 'P', {
          locale: locales[locale]
        })}
        inputFormat='L'
        componentsProps={{
          actionBar: {
            // The actions will be the same between desktop and mobile
            actions: ['clear']
          }
        }}
        PopperProps={{
          placement: 'bottom-start',
          anchorEl: anchorEl
        }}
        value={value}
        onChange={(newValue) => {
          props.onChange && props.onChange(newValue)
          if (newValue) {
            setFieldValue(name, newValue)
          } else {
            setFieldValue(name, '')
          }
        }}
        renderInput={(params) => (
          <input
            onChange={() => {}}
            onBlur={() => {
              setFocus(false)
              onBlur && onBlur()
            }}
            onFocus={() => {
              setFocus(true)
              onFocus && onFocus()
            }}
            value={
              (value &&
                format(new Date(value), 'P', {
                  locale: locales[locale]
                })) ||
              ''
            }
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
              setDatePickerOpen(true)
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePickerUi
